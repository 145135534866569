import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDocument } from 'app/services/api5-service/api.interface';
import { docInjectionToken } from 'components/chat/chat.interface';
import { IFormButtonUi } from 'components/form-button';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ValidationLoader } from './config/validations.loader';
import { IFormModel } from './dynamic-form.interface';
import { DynamicFormService } from './dynamic-form.service';
import {
  ISPFieldConfig,
  ISPFormOptions,
  ISPFieldType,
  DynamicFormContext,
} from './model';
import { ButtonsService } from './services/buttons.service';
import { CaptchaService } from './services/captcha.service';
import { ConditionService } from './services/condition.service';
import { DisabledService } from './services/disabled.service';
import { DrawerChildService } from './services/drawer-child.service';
import {
  DrawerParentService,
  ISucceededDrawerSelectMetadata,
} from './services/drawer-parent.service';
import { HiddenService } from './services/hidden.service';
import { LayoutService } from './services/layout.service';
import { ListFieldService } from './services/list-field.service';
import { MixedService } from './services/mixed-service';
import { ModeService } from './services/mode.service';
import { SelectService } from './services/select.service';
import { SetValuesService } from './services/set-values.service';
import { DynamicFormTemplateDirective, TemplateConfig } from './types';
import { FIELD_HIDDEN_CLASS } from './utils/class-fields';
import { mergeConfigs } from './utils/formly-configs';
import { getGeneralBackendError } from './wrappers/validation-error/validation-error.utils';

/**
 * Field list component
 *
 * Usage:
 * ```html
 * <isp-dynamic-form [doc]="doc$ | async"></isp-dynamic-form>
 * ```
 */
@UntilDestroy()
@Component({
  selector: 'isp-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./scss/dynamic-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DynamicFormService,
    SetValuesService,
    ValidationLoader,
    ConditionService,
    DisabledService,
    ModeService,
    HiddenService,
    ButtonsService,
    MixedService,
    ListFieldService,
    SelectService,
    LayoutService,
    DrawerParentService,
    DrawerChildService,
    CaptchaService,
    {
      provide: docInjectionToken,
      useExisting: DynamicFormService,
    },
  ],
})
export class DynamicFormComponent implements OnInit, AfterViewInit {
  private readonly doc$: BehaviorSubject<IDocument | null> =
    new BehaviorSubject(null);

  readonly messages$ = this.doc$.pipe(
    filter(d => Boolean(d)),
    map(d => d.messages.msg),
  );

  readonly serverErrorToShow$ = new BehaviorSubject<string | null>(null);

  /** footer buttons list */
  readonly buttonList$ = this.buttonsService.getFooterButtons$();

  /** Form group */
  get formGroup(): FormGroup {
    return this.dynamicFormService.formGroup;
  }

  get fieldList(): ISPFieldConfig[] {
    return this.dynamicFormService.fieldList;
  }

  get options(): ISPFormOptions {
    return this.dynamicFormService.options;
  }

  readonly isBlocked$ = this.setValuesService.blockedSetValues$;

  @Input() set validationBoundingElement(element: string) {
    this.dynamicFormService.validationBoundingElement = element;
  }

  @Input() set dropdownParentSelector(element: string) {
    this.dynamicFormService.dropdownParentSelector = element;
  }

  /** context information for dynamic-form */
  @Input() set context(context: DynamicFormContext) {
    this.dynamicFormService.context = context;
  }

  @Input() set showHints(canShow: boolean) {
    this.dynamicFormService.showHints = canShow;
  }

  /** dropdown list width. It cannot be setted by css, cause dropdown opens out of dynamic-form css scope */
  @Input() set listWidth(width: string) {
    this.dynamicFormService.listWidth = width;
  }

  /** A name of extandable select field inside the form, if passed the form should display in drawer mode (i.e. show only drawer-fileds related to that select) */
  @Input() set isDrawerFor(name: string) {
    this.dynamicFormService.isDrawerFor = name;
  }

  /** set form model */
  @Input() set model(model: IFormModel | null) {
    this.dynamicFormService.model = model || {};
  }

  get model(): IFormModel {
    return this.dynamicFormService.model;
  }

  @Input() set initialSucceededDrawerSelectsMetadata(
    metadata: Record<string, ISucceededDrawerSelectMetadata>,
  ) {
    this.dynamicFormService.initialSucceededDrawerSelectsMetadata = metadata;
  }

  /** should the form initialize in base mode (when it comes with two modes) */
  @Input() set startInBaseMode(isBase: boolean) {
    this.dynamicFormService.startInBaseMode = isBase;
  }

  /** document instance */
  @Input() set doc(doc: IDocument | null) {
    this.doc$.next(doc);
  }

  @Input() canShowFooter = true;

  @Input() displayGeneralError = false;

  @Input() showBlockingPrealoder = true;

  /** collapsible's toggle event */
  @Output() readonly collapseToggle = this.dynamicFormService.collapseEvent;

  /** button click event */
  @Output() readonly buttonClick = this.dynamicFormService.buttonClickEvent;

  /** link click event */
  @Output() readonly linkClick = this.dynamicFormService.linkClickEvent;

  /** change model event */
  @Output() readonly changeModel = this.dynamicFormService.changeModelEvent;

  @Output() readonly succeededDrawerSelectsUpdate =
    this.dynamicFormService.succeededDrawerSelectsMetadataUpdateEvent;

  /** templates for dynamic-form */
  @ContentChildren(DynamicFormTemplateDirective)
  contentTemplates: QueryList<DynamicFormTemplateDirective>;

  /** templates for dynamic-form */
  @ViewChildren(DynamicFormTemplateDirective)
  viewTemplates: QueryList<DynamicFormTemplateDirective>;

  @Input('buttonsConfig') buttonsConfigPartial: Partial<TemplateConfig>;

  buttonsConfig: TemplateConfig = {
    type: ISPFieldType.Template,
    templateOptions: {
      layoutPlace: 'footer',
      order: 0,
      buttonListSubject: this.buttonList$,
      isHidden:
        (!this.canShowFooter ||
          !Boolean(this.buttonsService.getFooterButtons().length)) &&
        !Boolean(this.dynamicFormService.options?.formState.doc.error),
    },
    expressionProperties: {
      'templateOptions.isHidden': (_, formState) =>
        (!this.canShowFooter ||
          !Boolean(this.buttonsService.getFooterButtons().length)) &&
        !Boolean(formState.doc.error),
      className: (_, __, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ? FIELD_HIDDEN_CLASS : '',
    },
  };

  generalErrorConfig: TemplateConfig = {
    type: ISPFieldType.Template,
    templateOptions: {
      layoutPlace: 'header',
      order: 3,
    },
    expressionProperties: {
      'templateOptions.errorMsg': () =>
        getGeneralBackendError(
          this.dynamicFormService.fieldList,
          this.dynamicFormService.options.formState,
        ),
      'templateOptions.isHidden': (_, __, fieldConfig) =>
        !this.displayGeneralError || !fieldConfig.templateOptions.errorMsg,
      className: (_, __, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ? FIELD_HIDDEN_CLASS : '',
    },
  };

  constructor(
    private readonly buttonsService: ButtonsService,
    private readonly setValuesService: SetValuesService,
    private readonly dynamicFormService: DynamicFormService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.buttonsConfig = mergeConfigs(
      this.buttonsConfig,
      this.buttonsConfigPartial as TemplateConfig,
    );
  }

  dismissServerError(): void {
    this.serverErrorToShow$.next(null);
  }

  ngAfterViewInit(): void {
    this.doc$
      .pipe(
        filter(doc => Boolean(doc)),
        untilDestroyed(this),
      )
      .subscribe(doc => {
        this.serverErrorToShow$.next(
          (doc.error?.msg as any)?.$?.replace(/\//g, '/&#8203;'),
        );

        const contentTemplates = Array.from(this.contentTemplates || []);
        const viewTemplates = Array.from(this.viewTemplates || []);
        const templates = [...viewTemplates, ...contentTemplates].map(
          template => template.config,
        );
        this.dynamicFormService.templates = templates;

        this.dynamicFormService.init(doc);

        // settimeout in order to avoid using 'detectChanges' in ngAfterViewInit due to ChangeDetectionStrategy.OnPush strategy
        setTimeout(() => {
          this.cdr.markForCheck();
        });
      });
  }

  /**
   * Emits the form button click event and form's value
   *
   * @param button - clicked button
   */
  emitButtonClick(button: IFormButtonUi): void {
    this.dynamicFormService.emitButtonClick(button);
  }

  /**
   * Sends the keyboard event to submit
   *
   * @param event - keyboard "Enter" key press event
   */
  submitFromKeyboard(event: KeyboardEvent): void {
    this.dynamicFormService.submitFromKeyboard(event);
  }

  /**
   * Reset the form
   */
  resetForm(): void {
    this.dynamicFormService.resetForm();
  }

  reset(): void {
    this.dynamicFormService.reset();
  }

  /**
   * TrackBy function for buttons
   *
   * @param _index - index button
   * @param button - button object
   */
  trackByButtonFn(_index: number, button: IFormButtonUi): string {
    return `${button.$name}${button.disabledSubject.value ? 'yes' : 'no'}`;
  }
}
