<div class="ispui-formly-textarea-field_buttons-block">
  <ispui-tooltip *ngIf="to.quote" [title-text]="to.quote.hint">
    <ispui-link
      type="default-hover"
      (mousedown)="preventSelectionReset($event)"
      (click)="insertQuote()"
    >
      {{ to.quote.msg }}
    </ispui-link>
  </ispui-tooltip>
  <isp-saved-messages
    *ngIf="to.savedMessage"
    [formState]="formState"
    [textAreaElement]="textarea"
    [savedMessageMeta]="to.savedMessage"
    (selectTemplate)="applyTemplate($event)"
  ></isp-saved-messages>
</div>
<ispui-textarea
  [id]="id"
  [name]="key"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [label]="to.inputLabel"
  [required]="to.required"
  [id]="id"
  [invalid]="showError"
  [class.isp-full-width]="to.isFullWidth"
  [placeholder]="getPlaceholder()"
  forceplaceholder
  [wrap]="to.wrap"
  [rows]="to.rows"
  (customFocus)="toggleFocus(true)"
  (customBlur)="toggleFocus(false)"
  #textarea
></ispui-textarea>
